/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
// import QS from "qs";
// import store from "../store";
import router from "../router";
// 环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "http://192.168.99.109:8711/";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "https://api.h5.2022.castmeeting.cn";
}

// 请求超时时间
axios.defaults.timeout = 10000;
axios.defaults.headers.Authorization =
  localStorage.getItem("Authorization") || "";

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    const Authorization = localStorage.getItem("Authorization") || "";
    Authorization && (config.headers.Authorization = Authorization);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code == 401) {
        localStorage.removeItem("Authorization");
        router.replace({
          path: "/",
        });
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      console.log(error.response.status, 222222222);
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: "/",
            // query: { redirect: router.currentRoute.fullPath }
          });
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          // 清除token
          // localStorage.removeItem("Authorization");
          // store.commit('loginSuccess', null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          // setTimeout(() => {
          // router.replace({
          //   path: "/",
          //   query: {
          //     redirect: router.currentRoute.fullPath,
          //   },
          // });
          // }, 1000);
          break;
        // 404请求不存在
        case 404:
          // alert('接口请求失败')
          // this.$message({type:"error",message:'接口请求失败',duration:'1000'})
          this.$message({
            type: "error",
            message: error.response.msg,
            duration: 1000,
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          this.$message({
            type: "error",
            message: error.response.msg,
            duration: 1000,
          });
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: "post",
    responseType: "blob",
    headers: {
      accept: "application/json, text/plain,*/*",
      "Content-Type": "application/json",
      "Content-Encoding": "gzip",
    },
  });
}
export function getRole(url, params) {
  return new Promise((resolve, reject) => {
    axios
        .get(url + "/" +params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
  });
}
export function getRoleType(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url + "/" +params.meetingId+'/'+params.roleType)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function postMaster(url, params) {
  return new Promise((resolve, reject) => {
    axios
        .post(url+'/'+params.meetingId+'/'+params.masterPhone, {})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
  });
}
/*
 *put 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
export function puts(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, { params: params })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
export function det(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url + "/" + params, {})
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (err) {
        reject(err);
      });
  });
}
